<template>
    <div class="NftList">
        <UiLoader v-if="isUserLoading" theme="block" :loading="true" />
        <!-- MERGE STATE 1 -->
        <template v-else>
            <div class="NftList__merge" v-if="userLvl > 3 && !didReceiveEmptyNFT">
                <p>
                    {{ $t("nfts.minting") }}
                    <span>{{ $t("nfts.youCanMintJustNFT") }}</span>
                </p>
                <button type="button" class="btn-merge" @click="createJustNFT">Create</button>
            </div>
            <!-- MERGE STATE 2 -->
            <div class="NftList__merge" v-else-if="userLvl < 4">
                <p>
                    {{ $t("nfts.minting") }}
                    <span>
                        {{ $t("nfts.justNFTRequirements") }}
                    </span>
                </p>
                <router-link :to="{ name: 'classic' }" type="button" class="btn-merge">{{
                    $t("nfts.reachLevel5")
                }}</router-link>
            </div>
        </template>
        <UiLoader v-if="isNftsLoading" theme="block" :loading="true" />
        <div v-else class="nft__list">
            <!--            <div class="nft__list-item">-->
            <!--                <div class="item__title">-->
            <!--                    <p class="name">-->
            <!--                        <span>JUST NFT</span>-->
            <!--                        Royal NFT-->
            <!--                    </p>-->
            <!--                    <p class="exp">1300/3500</p>-->
            <!--                    <div class="progressbar" style="width: 37%"></div>-->
            <!--                </div>-->
            <!--                <div class="item__wrapper">-->
            <!--                    <div class="item__wrapper-img">-->
            <!--                        <img src="/assets/mocks/just-nft.png" alt="just-nft" />-->
            <!--                        <div class="item__wrapper-address">0xbv8...D8735</div>-->
            <!--                    </div>-->
            <!--                </div>-->
            <!--            </div>-->
            <router-link
                :to="{ name: 'nft', params: { contractAddress: nft.contractAddress, tokenId: nft.tokenId } }"
                v-for="nft of nfts"
                :key="nft.contractAddress + '-' + nft.tokenId"
            >
                <div class="nft__list-item">
                    <div class="item__title">
                        <p class="name">
                            <span>{{ nft.nftMetadata.name }}</span>
                            Royalty NFT
                        </p>
                    </div>
                    <div class="item__wrapper">
                        <div class="item__wrapper-img">
                            <img :src="nft.nftMetadata.image" alt="nft image" />
                            <div class="item__wrapper-address">0xbv8...D8735</div>
                        </div>
                    </div>
                </div>
            </router-link>
        </div>
    </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations, mapState } from "vuex"
import pageReadyByConnect from "@/mixin/pageReadyByConnect"
import nftsApi from "@/api/nftsApi"

export default {
    name: "NftList",
    computed: {
        ...mapState("user", ["classicMatrix"]),
        ...mapGetters("user", ["totalActiveLevels", "getLastActive"]),
        ...mapGetters("crypto", ["getAccount", "isConnected"]),
        userLvl() {
            return this.getLastActive ? this.getLastActive.lvl : -1
        }
    },
    mixins: [pageReadyByConnect],
    data() {
        return {
            isUserLoading: true,
            isNftsLoading: true,
            didReceiveEmptyNFT: true,
            nfts: [],
            intervalTimers: []
        }
    },
    methods: {
        ...mapActions("crypto", [
            "getProgramLevels",
            "checkRegistration",
            "getJustNFT",
            "levelUpJustNFTBySFC",
            "alreadyReceivedEmptyNFT"
        ]),
        ...mapActions("user", ["guessUser"]),
        ...mapMutations("user", ["setClassicMatrix", "updateClassicMatrixLevels"]),
        async pageReady() {
            console.log("pageReady")
            const [regErr, regParent] = await this.checkRegistration(true)
            if (regErr) {
                this.$swal(this.$t("errors.registrationError"))
                await this.$router.push({ name: "signup" })
                return
            }

            this.loadNfts()

            const intervalId = setInterval(() => {
                this.loadNfts(false)
            }, 5000)
            this.intervalTimers.push(intervalId)

            await this.checkAlreadyReceivedEmptyNFT()

            try {
                let userResponse = await this.guessUser(this.getAccount)
                if (!userResponse) throw new Error()

                this.id = userResponse.name
                this.account = userResponse.account
                this.userData = userResponse
                this.setClassicMatrix(userResponse.classic)

                const [err, levels] = await this.getProgramLevels(userResponse.account)

                this.updateClassicMatrixLevels(levels)
            } catch (err) {
                this.$swal(this.$t("errors.userNotFound"))
                await this.$router.push({ name: "dashboard" })
            } finally {
                this.isUserLoading = false
            }
        },
        async createJustNFT() {
            const [err, responce] = await this.getJustNFT({
                onBlockchainPending: () => {
                    this.$store.commit("loader/showProcessingModal", {
                        isShown: true,
                        text: `${this.$t("matrix.activationModal.blockchainPendingFirstLine")} <br />
                              ${this.$t("matrix.activationModal.blockchainPendingSecondLine")}`
                    })
                }
            })

            this.$store.commit("loader/showProcessingModal", {
                isShown: false
            })

            if (err) {
                this.$toast.error(this.$t("nfts.error.createJustNftError"))
            } else {
                await this.checkAlreadyReceivedEmptyNFT()
            }

            console.log("err", err)
            console.log("responce", responce)
        },
        loadNfts(withLoading = true) {
            if (withLoading) {
                this.isNftsLoading = true
            }
            nftsApi
                .nfts({
                    owner: this.getAccount
                })
                .then(({ data }) => {
                    this.nfts = data
                    console.log(data)
                })
                .catch((err) => {
                    console.log("err", err)
                })
                .finally(() => {
                    if (withLoading) {
                        this.isNftsLoading = false
                    }
                })
        },
        async checkAlreadyReceivedEmptyNFT() {
            this.didReceiveEmptyNFT = await this.alreadyReceivedEmptyNFT({ address: this.getAccount })
        }
    },
    async mounted() {
        await this.checkAlreadyReceivedEmptyNFT()
        if (this.isConnected) {
            await this.pageReady()
        }
    },
    beforeDestroy() {
        for (const id of this.intervalTimers) {
            clearInterval(id)
        }
        this.intervalTimers = []
    }
}
</script>

<style lang="scss">
.NftList {
    margin-bottom: 45px;

    &__merge {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 8px;
        padding: 32px 24px;
        background-color: var(--color-cinder);
        border-radius: 8px;

        p {
            display: flex;
            flex-direction: column;
            max-width: 50%;
            font-weight: 500;
            font-size: 32px;
            line-height: 40px;
            color: #e6e6e6;

            span {
                margin-top: 8px;
                font-weight: 400;
                font-size: 14px;
                line-height: 20px;
                color: rgba(255, 255, 255, 0.4);
            }

            @media (max-width: 480px) {
                max-width: 100%;
            }
        }

        .btn-merge {
            display: flex;
            justify-content: center;
            align-content: center;
            padding: 8px 32px;
            background: #ff9501;
            border-radius: 8px;
            font-weight: 600;
            font-size: 14px;
            color: #ffffff;
            border: none;
            cursor: pointer;

            &.disabled {
                background: rgba(255, 255, 255, 0.03);
                color: rgba(255, 255, 255, 0.2);
                cursor: default;
                pointer-events: none;
            }

            @media (max-width: 480px) {
                margin-top: 24px;
                width: 100%;
            }
        }

        @media (max-width: 480px) {
            flex-direction: column;
        }
    }

    .nft__list {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        grid-gap: 8px;
        width: 100%;

        &-item {
            display: flex;
            flex-direction: column;
            //background: linear-gradient(180deg, #191919 7.23%, #191919 31.8%, #1b1b1b 51.74%, #1b1b1b 94.58%);
            background-color: var(--color-cinder);
            border-radius: 10px;
            overflow: hidden;
            cursor: pointer;

            &:hover,
            &:focus {
                //outline: 2px solid var(--color-mustard);
                outline: 2px solid var(--color-yellow);
            }

            .item__title {
                display: flex;
                align-items: center;
                justify-content: space-between;
                position: relative;
                padding: 24px;
                font-weight: 500;
                font-size: 14px;
                line-height: 20px;
                letter-spacing: -0.01em;
                color: rgba(255, 255, 255, 0.4);
                background: rgba(255, 255, 255, 0.03);
                backdrop-filter: blur(20px);

                .name {
                    display: flex;
                    align-items: center;

                    span {
                        display: flex;
                        align-items: center;
                        color: var(--color-white);

                        &::after {
                            width: 4px;
                            height: 4px;
                            margin: 0 8px;
                            background: rgba(255, 255, 255, 0.4);
                            border-radius: 50%;
                            content: "";
                        }
                    }
                }

                .exp {
                    font-weight: 600;
                    font-size: 14px;
                    line-height: 20px;
                    text-align: right;
                    color: #e6e6e6;
                }

                .progressbar {
                    height: 100%;
                    position: absolute;
                    top: 0;
                    left: 0;
                    background: linear-gradient(180deg, rgba(255, 149, 1, 0) 0%, rgba(255, 149, 1, 0.2) 100%);
                    border-bottom: 4px solid #ff9501;
                }

                @media (max-width: 768px) {
                    font-size: 22px;
                }
            }

            .item__wrapper {
                display: flex;
                align-items: center;
                justify-content: center;
                padding: 30px;

                &-img {
                    //width: 62%;
                    position: relative;

                    img {
                        width: 100%;
                    }
                }

                &-address {
                    font-weight: 400;
                    font-size: 7px;
                    line-height: 8px;
                    letter-spacing: 0.2em;
                    color: #02d467;
                    transform: rotate(-90deg);
                    position: absolute;
                    right: -26px;
                    top: 34%;
                }
            }
        }

        @media (max-width: 1250px) {
            grid-template-columns: repeat(2, 1fr);
        }

        @media (max-width: 768px) {
            grid-template-columns: repeat(1, 1fr);
        }
    }

    .btn-merge {
        width: 100%;
        max-width: 195px;
        height: 40px;
        padding: 8px 10px;
        font-size: 16px;
        color: #161620;
        background: #fedd00;
        border-radius: 20px;
    }

    .btn-add {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100px;
        height: 100px;
        padding: 6px;
        position: relative;
        font-weight: 400;
        font-size: 14px;
        line-height: 21px;
        letter-spacing: 0.01em;
        color: #ffffff;
        background: none;
        border: 1px solid rgba(255, 255, 255, 0.1);
        border-radius: 7px;
        cursor: pointer;

        span {
            position: absolute;
            top: 6px;
            left: 8px;
        }
    }

    .btn-upgrade {
        width: 137px;
        height: 39px;
        margin-left: auto;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        color: #ffffff;
        background: none;
        border: 1px solid #ffffff;
        border-radius: 10px;
        cursor: pointer;

        &.disabled {
            opacity: 0.2;
            pointer-events: none;
        }
    }

    .btn-more {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 354px;
        height: 39px;
        margin: 40px auto 16px;
        font-weight: 700;
        font-size: 16px;
        line-height: 19px;
        color: #f4f4f4;
        border: 1px solid #ffffff;
        border-radius: 10px;
    }
}
</style>
